<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <ul v-if="appState.loggedIn" class="nav nav-pills nav-stacked affix">
                <li v-show="!showShipperNav && isEmployee" >
                    <router-link :to="'/timecard'">Timecard</router-link>
                </li>
                <li v-show="!showShipperNav && isEmployee" >
                    <router-link :to="'/myalerts'">Alerts</router-link>
                </li>
                <li v-show="!showShipperNav && isEmployee" >
                    <router-link :to="'/clock#cdi_values'">Recognition</router-link>
                </li>
                <li v-show="!showShipperNav && isEmployee" >
                    <a class="pointer" @click="openModal('suggestion')">Suggestions</a>
                </li>
                <li v-show="!showShipperNav && isEmployee" >
                    <a class="pointer" @click="openModal('helpdesk')">Help Desk Ticket</a>
                </li>
                <!-- todo: ONLY SHOW FOR price_level_id PLATINUM, GOLD, ELITE, BASE, TRANSIT_1, TRANSIT_2 -->
                <li v-show="!showShipperNav">
                    <router-link :to="'/drawings'">Drawings</router-link>
                </li>
                <li v-show="!showShipperNav && !isEmployee">
                    <router-link :to="'/quote'">Quotes</router-link>
                </li>
                <li v-show="!showShipperNav && !isEmployee">
                    <router-link :to="'/myorder'">Orders</router-link>
                </li>
                <li v-show="showShipperNav">
                    <router-link :to="'/deliveries'">Deliveries</router-link>
                </li>
                <li>
                    <router-link :to="'/myedit'">Edit Account</router-link>
                </li>
                <li>
                    <a class="pointer" @click="$appStore.signOut()">Sign Out</a>
                </li>
            </ul>
            <ul v-else class="nav nav-pills nav-stacked affix">
                <li>
                    <router-link :to="'/pass_reset'">Lost Password?</router-link>
                </li>
                <li>
                    <router-link :to="'/my/register'">Register</router-link>
                </li>
            </ul>
        </nav>


        <!-- Suggestions Modal -->
        <Modal v-if="openSuggestionModal" :heading="'Suggestions are NOT anonymous.'" @close="closeSuggestions">
            <template v-slot:body>
                <p>You are submitting a suggestion as <strong>{{ state.data.name }}</strong></p>

                <div class="form-group">
                    <label>Notes:</label>
                    <textarea class="form-control spacing_btm" v-model="suggestionTextarea" rows="5" :placeholder="'Add Your notes here.'"></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <button class="btn btn-primary spacing_right" @click="sendSuggestion">Submit</button>
                <button class="btn btn-default" @click="closeSuggestions">Cancel</button>
            </template>
        </Modal>

        <!-- Help Desk Modal -->
        <Modal v-if="openHelpDeskModal" :heading="'Help Desk Ticket'" @close="closeHelpDeskModal">
            <template v-slot:body>
                <p>You are submitting a help desk ticket as <strong>{{ state.data.name }}</strong></p>
                <p>Describe your issue and include an asset ID if applicable.</p>
                <div class="form-group">
                    <label>Notes:</label>
                    <textarea class="form-control spacing_btm" rows="5" v-model="helpDeskTextarea" :placeholder="'Add Your notes here.'"></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <button class="btn btn-primary spacing_right" @click="sendTicket">Submit</button>
                <button class="btn btn-default" @click="closeHelpDeskModal">Cancel</button>
            </template>
        </Modal>

    </div>
</template>

<script>
import {myStore} from "@/store/My.store";
import Modal from "@/components/utils/Modal";

export default {
    name: 'Nav',
    components: {
        Modal
    },
    data() {
        return {
            appState: this.$appStore.state,
            state: myStore.state,
            openSuggestionModal: false,
            openHelpDeskModal: false,
            suggestionTextarea: '',
            helpDeskTextarea: ''
        }
    },
    methods: {
        sendSuggestion: function() {
            let params = {
                'params[feedback]': this.suggestionTextarea,
                'params[user_id]': this.$appStore.state.user.userID
            }
            myStore.api('mycdi/save_feedback', params);
            this.suggestionTextarea = '';
            this.closeSuggestions();
        },
        closeSuggestions: function() {
            this.openSuggestionModal = false;
        },
        closeHelpDeskModal: function() {
            this.openHelpDeskModal = false;
        },
        sendTicket: function() {
            let params = {
                'params[issue]': this.helpDeskTextarea,
                'params[user_id]': this.$appStore.state.user.userID
            }
            myStore.api('mycdi/send_help_desk_ticket', params)
            this.helpDeskTextarea = '';
            this.closeHelpDeskModal();
        },
        openModal: function(type) {
            if (type === 'helpdesk') {
                this.openHelpDeskModal = true;
            }
            if (type === 'suggestion') {
                this.openSuggestionModal = true;
            }
        },
    },
    computed: {
        showShipperNav: function() {
            return this.appState.user.userTypeID == this.$cdiVars.USER_TYPE_SHIPPER;
        },
        isEmployee: function() {
            return this.$appStore.isStaff();
        }
    }
}

</script>

<style scoped>

</style>